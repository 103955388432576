import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { AuthContext } from '@/contexts/auth-context';
import { MerchantContext } from '@/contexts/merchant-context';

function Home() {
  const router = useRouter();
  const { isUserLoggedIn } = useContext(AuthContext);
  const { merchantId } = useContext(MerchantContext);

  const newRoute = isUserLoggedIn ? `/${merchantId}/details` : `/${merchantId}/login`;
  router.push(newRoute);
  return <></>;
}

export default Home;
